<template>
  <section class="list-image">
    <div class="container">
      <div class="img-side backdrop backdrop-xl blackdrop">
        <slot name="image"></slot>
      </div>
      <div class="text-side">
        <slot name="text"></slot>
      </div>
    </div>
  </section>
</template>

<style lang="scss">

$gold: #D6AD60;
$gold-d2: #9a7836;
$coal: #122620;

.list-image {
  .container {
    display: flex;
    gap: 30px;
    justify-content: center;
    @media (max-width: 800px) {
      flex-direction: column;
    }
    .img-side {
      @media (max-width: 800px) {
        width: 100%;
        max-width: unset;
        height: 300px;
      }
      width: 50%;
      max-width: 400px;

      img {
        object-fit: cover;
        width: 100%;
        max-height: 100%;
      }
    }
    .text-side {
      display: flex;
      flex-direction: column;
      ul {
        padding-left: unset;
        li {
          list-style-type: none;
          display: flex;
          gap: 15px;
          align-items: center;
          opacity: .8;
          &:hover {
            opacity: 1;
          }
          svg {
            width: 20px;
            max-height: 20px;
          }
          a {
            font-weight: 500;
            text-decoration: none;
            font-size: 24px;
            color: $coal;
          }
        }
      }
      p {
        opacity: .8;
        margin-top: 15px;
        font-weight: 500;
      }
      .social-info {
        margin-top: auto;
        margin-bottom: 10px;
      }
      .social-medias {
        display: flex;
        gap: 15px;
        svg {
          fill: $coal;
          width: 30px;
          opacity: .8;
          &:hover {
            opacity: 1;
          }
          * {
            fill: $coal;
          }
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: 'TextImage',
  props: {
    flip: Boolean
  }
}
</script>
